//== Grid system
// Colunas do grid
$grid-columns:          12;

//Espaçamento entre os elementos
$element-margin:        8px;

//== Clors

$color-primary: #088A68;
$nexus-cloud-primary: #088A68;
$color-secondary: #04B486;
$color-active: rgb(15, 113, 88);

//Menu colors
$hamburger-hover: rgba(255, 255, 255, 0.06);

$menu-headnavbar-backgorund-color: $color-primary;
$menu-background-color-nivel-1: $color-primary;
$menu-background-color-ativo: $color-active;
$menu-hover-color: $color-active;

//Menu size
$menu-base-width: 66px;
$menu-full-width: 348px;
$menu-spacing-right: 24px;