@use '@angular/material' as mat;
@import '@angular/material/prebuilt-themes/pink-bluegrey.css';

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

// The custom palete
$mat-nexus: (
  50: #e0f2f1,
  100: #b2dfdb,
  200: #80cbc4,
  300: #4db6ac,
  400: #26a69a,
  500: #00bfa5,
  600: #00897b,
  700: #00796b,
  800: #00695c,
  900: #004d40,
  A100: #a7ffeb,
  A200: #18d6aa,
  A400: #1de9b6,
  A700: #00bfa5,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: rgba(white, 0.87),
    900: rgba(white, 0.87),
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: rgba(black, 0.87),
    A700: rgba(black, 0.87),
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
$nexus-app-primary: mat.define-palette($mat-nexus);
$nexus-app-accent: mat.define-palette($mat-nexus, A200, A100, A400);

// The warn palette is optional (defaults to red).
$nexus-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$nexus-app-theme: mat.define-light-theme($nexus-app-primary, $nexus-app-accent, $nexus-app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($nexus-app-theme);
@include mat.all-legacy-component-themes($nexus-app-theme);

body {
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hidden {
  display: none !important;
}

html,
body,
material-app,
mat-sidenav-container {
  margin: 0;
  width: 100%;
  //height: 100%;
  background: #f3f5f9;
}

.mat-card {
  box-shadow: 0 2px 3px 2px rgba(0, 0, 0, 0.03) !important;
}

@import './app/shared/styles/main';

ag-grid-angular {
  .ag-body-container,
  .ag-header-row {
    width: 100% !important;
  }

  .ag-cell,
  .ag-header-cell {
    // display: inline-flex !important;
    position: initial !important;
  }
}
