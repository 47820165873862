//Grid usando flexbox

[row-inline] {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-left: $element-margin * -1;
  margin-right: $element-margin * -1;
}

[layout-flex] {
  flex: 1;
  margin-right: $element-margin;
}

[hidden] {
  display: none !important;
}

@for $i from 1 through $grid-columns {
  [col-#{$i}], .col-#{$i} {
    margin-right: $element-margin;
    margin-left: $element-margin;
    width: calc(#{$i * (100% / $grid-columns)} - #{$element-margin * 2}) !important;
  }
}