mat-table {

    mat-header-row {
        font-size: 12px;
        font-weight: 500;
    }
        
    mat-row {
        font-size: 12px;
    }

}